import React from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";

interface DataProp {
  data: IDetailsSubscription;
}

export const DadosPripriedade: React.FC<DataProp> = ({ data }) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Nome de quem está fazendo a inscrição:{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.resp?.nome_resp}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Nome do produtor: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.resp?.nome_prod}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone do consultor: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.resp?.phone_resp}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone do proprietário: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.resp?.phone_prod}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>E-mail do consultor: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.resp?.email_resp}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>E-mail do proprietário: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.resp?.email_prod}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Fazenda inscrita: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.property?.fazenda}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Endereço da fazenda inscrita: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.endereco}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Cidade da fazenda inscrita: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.property?.cidade}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>UF: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.property?.estado}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Altitude da fazenda(m): </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.altitude}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Tempo de atividade(anos): </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.tempo_atividade}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área total do imóvel(ha): </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.area_total}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área própria(% total): </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.area_propria}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Área arrendada(% total): </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.area_arrendada}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Tem sucessor? </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.sucessor}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Tem herdeira? </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.property?.herdeiro}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
    </C.ContainerDadosPropriedade>
  );
};
