import React, { useMemo } from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";

interface DataManejo {
  data: IDetailsSubscription;
}

export const InfoManejoNutricaoPlantas: React.FC<DataManejo> = ({ data }) => {
  const mInfos = useMemo(() => {
    const PRESEMEADURA =
      data.inscricao.nutrition?.qtd_pre_seme !== undefined &&
      data.inscricao.nutrition?.qtd_pre_seme > 0
        ? data.lista.filter((i) => i.nome === "PRESEMEADURA")
        : [];
    const SEMEADURA =
      data.inscricao.nutrition?.qtd_seme !== undefined &&
      data.inscricao.nutrition?.qtd_seme > 0
        ? data.lista.filter((i) => i.nome === "SEMEADURA")
        : [];
    const COBERTURA =
      data.inscricao.nutrition?.qtd_cobertura !== undefined &&
      data.inscricao.nutrition?.qtd_cobertura > 0
        ? data.lista.filter((i) => i.nome === "COBERTURA")
        : [];
    const FOLIAR =
      data.inscricao.nutrition?.qtd_foliar !== undefined &&
      data.inscricao.nutrition?.qtd_foliar > 0
        ? data.lista.filter((i) => i.nome === "FOLIAR")
        : [];
    const VEGETAL =
      data.inscricao.nutrition?.qtd_espec_fisio !== undefined &&
      data.inscricao.nutrition?.qtd_espec_fisio > 0
        ? data.lista.filter((i) => i.nome === "ESPECIALIDADES")
        : [];

    return {
      PRESEMEADURA,
      SEMEADURA,
      COBERTURA,
      FOLIAR,
      VEGETAL,
    };
  }, [data]);

  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabelTitleSection>Tipo de adubação</C.BoxLabelTitleSection>
      <br />
      <h4>
        Adubação pré-semeadura ({data.inscricao.nutrition?.qtd_pre_seme}{" "}
        aplicações):
      </h4>
      {mInfos.PRESEMEADURA.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.PRESEMEADURA.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fórmula/Nutriente NPK</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.estadio_veget_feno === "Outros" && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>
        Adubação de semeadura ({data.inscricao.nutrition?.qtd_seme} aplicações):
      </h4>
      {mInfos.SEMEADURA.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.SEMEADURA.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fórmula/Nutriente NPK</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.estadio_veget_feno === "Outros" && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>
        Adubação de cobertura ({data.inscricao.nutrition?.qtd_cobertura}{" "}
        aplicações):
      </h4>
      {mInfos.COBERTURA.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.COBERTURA.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fórmula/Nutriente NPK</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.estadio_veget_feno === "Outros" && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>
        Adubação foliar ({data.inscricao.nutrition?.qtd_foliar} aplicações):
      </h4>
      {mInfos.FOLIAR.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.FOLIAR.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fórmula/Nutriente NPK</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.estadio_veget_feno === "Outros" && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}

      <br />
      <h4>
        Especialidades/Fisiologia (condicionadores de solo,estimulantes, etc) (
        {data.inscricao.nutrition?.qtd_espec_fisio} aplicações):
      </h4>
      {mInfos.VEGETAL.length === 0 ? (
        <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
      ) : (
        mInfos.VEGETAL.map((pre) => (
          <>
            <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fórmula/Nutriente NPK</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
              </C.BoxLabel>
              <C.BoxLabel>
                <C.BoxLabelTitle>Estadio Fenologico</C.BoxLabelTitle>
                <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
              </C.BoxLabel>
              {pre?.estadio_veget_feno === "Outros" && (
                <C.BoxLabel>
                  <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                </C.BoxLabel>
              )}
            </C.BoxField3Col>
          </>
        ))
      )}
    </C.ContainerDadosPropriedade>
  );
};
