import { useCallback, useEffect, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { DataGrid, SelectBox } from 'devextreme-react';
import { TipoLista } from '../../data/data';
import { Column } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { TLista } from '../../types/PropertyTypes';
import api from '../../services/api';
import { toast } from 'react-toastify';
import FileUploader from 'devextreme-react/file-uploader';
import { BiPlus } from 'react-icons/bi';

export const CadastroListas = () => {
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [allListas, setAllListas] = useState<TLista[]>([]);

    const [tipoLista, setTipoLista] = useState('');

    const togglePopup = () => {
        setTipoLista('');
        setPopupVisibility(!isPopupVisible);
    }

    const listCupons = useCallback(() => {
        api.get('/adm/listas?page=1&quantity=1000')
            .then(res => {
                console.log(res.data);
                setAllListas(res.data)
            })
    }, [])

    const alteraLista = (filename : string) => {
        api.patch(`/adm/listas/${tipoLista}`, {
            nome_arquivo : filename,
            tipo: tipoLista
            })
            .then(res => {
                toast.success("Lista atualizada com sucesso!");
                listCupons();
                setPopupVisibility(false);
            }).catch((error) => {
                console.log(error.response.data.message);
                toast.error("Error ao atualizar lista.");
            }).finally(() => {
                setPopupVisibility(false)
            })
    }

      const handleUpload = async (e: any) => {
        const files = e.value as File[];
        if (!files || files.length === 0) {
          return;
        }
    
        const formData = new FormData();
    
        files.forEach((file) => {
          formData.append('file', file);
        });
    
        try {
          const responseUpload = await api.post(`/adm/upload/?tipoLista=${tipoLista}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
    
          console.log('Upload bem-sucedido:', responseUpload.data.msg);

          alteraLista(responseUpload.data.filename);

        } catch (error) {
          console.error('Erro ao fazer upload:', error);
        }
      };

    useEffect(() => {
        listCupons();
    }, [listCupons])

    return (
        <C.Container>
            <C.ContentRight>
                <C.HeaderContentRight>
                    <Title title='Cadastro de Listas' />
                </C.HeaderContentRight>

                <C.ContentRightForm>
                    <C.BoxFields>
                        <C.BoxRegulamento>
                            <C.BoxButtonSubmit>
                                <button onClick={togglePopup}>
                                    <BiPlus size={16} />
                                    Realizar novo upload
                                </button>
                            </C.BoxButtonSubmit>
                            <br />
                            <br />
                            <DataGrid
                                id="lista"
                                dataSource={allListas}
                                keyExpr="id"
                                showBorders={false}
                                showColumnLines={false}
                                columnResizingMode="nextColumn"
                                allowColumnResizing={true}
                                rowAlternationEnabled={true}
                            >
                                <Column caption="Id" dataField='id' width='60' />
                                <Column caption="Tipo" dataField='tipo' width='80' />
                                <Column caption="Qtd. registros" dataField='qtd_registros' />
                                <Column caption="Nome arquivo atual" dataField='nome_arquivo_atual' />
                                <Column caption="Nome arquivo anterior" dataField='nome_arquivo_anterior' />
                                <Column caption="Data última atualização" dataField='updated_at' />
                          
                            </DataGrid>
                        </C.BoxRegulamento>
                    </C.BoxFields>
                </C.ContentRightForm>

                <Popup
                    title='Upload de listas'
                    visible={isPopupVisible}
                    hideOnOutsideClick={true}
                    onHiding={togglePopup}
                    width={400}
                    height={400}
                >
                    <C.ContentModal>
                        <C.ContentModalInput>                            
                        <SelectBox
                                items={TipoLista}
                                value={tipoLista}
                                onValueChange={(e) => setTipoLista(e)}
                                stylingMode="underlined"
                                labelMode="static"
                                label={"Tipo de Lista (*)"}
                                width="100%"
                                height={50}
                                style={{ marginRight: "20px", color: "#444" }}
                            />
                            <FileUploader
                                    multiple={true} // Permite upload de múltiplos arquivos
                                    accept=".xls,.xlsx" // Aceita apenas arquivos de imagem
                                    uploadMode="useForm" // Botões de upload/manipulação de arquivos
                                    onValueChanged={handleUpload} // Manipulador de eventos para upload
                                    selectButtonText="Selecionar arquivo" // Texto do botão de seleção
                                    labelText="ou arraste o arquivo aqui" // Texto de arrastar arquivos
                                    disabled={tipoLista === undefined || tipoLista === ''}
                                />
                        </C.ContentModalInput>
                        <C.BoxButtonSubmitModal>
                            <button onClick={
                                ()=>setPopupVisibility(false)
                                }>                            
                                Ok
                            </button>
                        </C.BoxButtonSubmitModal>
                    </C.ContentModal>
                </Popup>                     

            </C.ContentRight>
        </C.Container>
    );
}