import "devextreme/dist/css/dx.light.css";
import { ReactNode } from "react";
import { Links } from "../../data/data";
import { Header } from "../Header/Header";
import * as C from "./styles";

import { NavLink } from "react-router-dom";
import { useMenu } from "../../hooks/useMenu";
import api from "../../services/api";
import { Button } from "devextreme-react";

type Props = {
  children: ReactNode;
};

export const Theme = ({ children }: Props) => {
  const { open } = useMenu();

  const handleExport = async () => {
    api.get("/adm/subscription-doc").then((res) => {
      if (res.data.Ok) {
        window.open("https://api.getap.agr.br/download");
      }
    });
  };

  const handleListExport = async () => {
    api.get("/adm/listsubscription-doc").then((res) => {
      if (res.data.Ok) {
        window.open("https://api.getap.agr.br/download-list");
      }
    });
  };

  return (
    <C.Container>
      <Header />

      <C.Area>
        <C.Steps>
          <C.Sidebar open={open}>
            <C.ContentLeft>
              <C.CheckSteps>
                {Links.map((item, index) => (
                  <C.BoxMenu key={index}>
                    <C.TitleSectionSidebar>{item.title}</C.TitleSectionSidebar>
                    {item.links.map((link, i) => (
                      <NavLink key={i} to={link.path}>
                        <C.LinNav>
                          {link.icon}&nbsp;{link.name}
                        </C.LinNav>
                      </NavLink>
                    ))}
                  </C.BoxMenu>
                ))}
                <Button onClick={handleExport}>Exportar Dados</Button>
                <Button onClick={handleListExport}>
                  Exportar Lista de Nutri/Cultivo
                </Button>
              </C.CheckSteps>
            </C.ContentLeft>
          </C.Sidebar>
          <C.Page>{children}</C.Page>
        </C.Steps>
      </C.Area>
    </C.Container>
  );
};
