import * as C from './styles';
import LogoGetap from '../../assets/png/getap.png';
import { BiLogIn, BiMenu } from 'react-icons/bi';
import { useAuth } from '../../hooks/auth';
import { useMenu } from '../../hooks/useMenu';

export const Header = () => {
    const { user, signOut } = useAuth();
    const { setMenu } = useMenu();

    return (
        <C.Header>
            <C.Logo>
                <img src={LogoGetap} alt="Getap" />
            </C.Logo>
            {/* <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/quem-somos">Quem somos</NavLink></li>
                <li><NavLink to="/eventos">Eventos</NavLink></li>
                <li><NavLink to="/noticias">Notícias</NavLink></li>
                <li><NavLink to="/contato">Contato</NavLink></li>
            </ul> */}
            <C.CtaSubscribe>
                <span>{user.nome}</span>
                <button onClick={signOut}><BiLogIn /> Sair</button>
            </C.CtaSubscribe>
            <C.MenuBars>
                <BiMenu onClick={setMenu}/>
            </C.MenuBars>
        </C.Header>
    )
}