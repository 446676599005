import { IDetailsSubscription } from "../../types/SystemTypes";
import * as C from "./styles";

type DataProp = {
  data: IDetailsSubscription;
};

export const InfoManejoCarrecaoSolo: React.FC<DataProp> = ({ data }) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabelTitleSection>Calagem de solo </C.BoxLabelTitleSection>

      <C.BoxAlignValues>
        <C.BoxLabel>
          <C.BoxLabelTitle>Frequência [a cada x anos]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.calagem_frequency}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Tipo de calcário:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.calagem_tipo_calcario}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Dose (ton/ha):</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.calagem_dose}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValues>

      <C.BoxLabelTitleSection>Gessagem </C.BoxLabelTitleSection>

      <C.BoxAlignValues>
        <C.BoxLabel>
          <C.BoxLabelTitle>Perído de realização (mês/ano):</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.gessagem_periodo}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Frequência [a cada x anos]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.gessagem_frequency}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Dose (ton/ha):</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.gessagem_dose}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValues>

      <C.BoxLabelTitleSection>Descompactação </C.BoxLabelTitleSection>

      <C.BoxAlignValuesInputsFinal>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Equipamento utilizado (sucador, subsolador, etc):
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.descompactacao_equipament}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Frequência [a cada x anos]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.area_info?.descompactacao_frequency}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValuesInputsFinal>
    </C.ContainerDadosPropriedade>
  );
};
