import styled from 'styled-components';

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 99px;
    color: #FFF;
    padding: 0 35px;
    background-color: #254D6B;

    ul {
        display: flex;
        width: 45%;
        list-style-type: none;

        li {
            margin-right: 25px;

            a {
                color: #FFF;
                text-decoration: none;
            }
            a.active {
                color: #27AE60;
            }

            @media screen and (max-width:760px){
                margin-right: 18px;
            }
        }

        @media screen and (max-width:760px){
            width: 100%;
            justify-content: center;
            font-size: .8rem;
            padding: 1%;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:120px;
    }

    @media print {
        display: none;
    }
`;

export const Logo = styled.div`
    img {
        @media screen and (max-width:760px){
            /* display: none; */
            width: 120px;
            height: 40px;
            margin-top: -10px;
        }
    }
`;

export const CtaSubscribe = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .4rem;
    height: 48px;
    color: #fff;

    button {
        display: flex;
        align-items: center;
        gap: .2rem;
        text-decoration: none;
        color: #FFF;
        margin-left: 15px;
        background: transparent;
        padding: 8px;
        font-size: 14px;;
        border: none;
    }
`;

export const MenuBars = styled.div`
    display: none;
    position: absolute;
    top: 40px;
    right: 20px;
    font-size: 2rem;
    cursor:pointer;

    @media screen and ( max-width:758px ) {
        display: block;
    }
`;