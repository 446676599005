import React, { useMemo } from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";
import { format } from "date-fns";

type DataProp = {
  data: IDetailsSubscription;
};

export const Agendamento: React.FC<DataProp> = ({ data }) => {
  const previsaoCol = useMemo(() => {
    return data.inscricao.appointment !== null
      ? format(
          new Date(data.inscricao.appointment.previsao_colheita),
          "dd/MM/yyyy"
        )
      : "-";
  }, [data]);

  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabel>
        <C.BoxLabelTitle>Previsão de colheita (dia/mês/ano) </C.BoxLabelTitle>
        <C.BoxLabelValue>{previsaoCol}</C.BoxLabelValue>
      </C.BoxLabel>
      <h4>Contato de quem acompanhará a auditoria:</h4>
      <C.BoxLabel>
        <C.BoxLabelTitle>Nome:</C.BoxLabelTitle>
        <C.BoxLabelValue>
          {data.inscricao.appointment?.ac_auditoria_name}
        </C.BoxLabelValue>
      </C.BoxLabel>
      <C.BoxField3Col>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone/WhatsApp [opção 01]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.appointment?.ac_auditoria_contato1}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Telefone/WhatsApp [opção 02]:</C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.appointment?.ac_auditoria_contato2}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField3Col>

      <C.BoxLabelTitleSection>
        Confirme os dados da inscrição:
      </C.BoxLabelTitleSection>
      <C.BoxLabel>
        <C.BoxLabelTitle>Nome do produtor:</C.BoxLabelTitle>
        <C.BoxLabelValue>{data?.inscricao.resp?.nome_prod}</C.BoxLabelValue>
      </C.BoxLabel>
      <C.BoxField3Col>
        <C.BoxLabel>
          <C.BoxLabelTitle>Cidade:</C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.property?.cidade}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Estado:</C.BoxLabelTitle>
          <C.BoxLabelValue>{data?.inscricao.property?.estado}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField3Col>
      <C.BoxLabel>
        <C.BoxLabelTitle>Nome da propriedade:</C.BoxLabelTitle>
        <C.BoxLabelValue>{data?.inscricao.property?.fazenda}</C.BoxLabelValue>
      </C.BoxLabel>
      <C.BoxLabel>
        <C.BoxLabelTitle>Talhão inscrito:</C.BoxLabelTitle>
        <C.BoxLabelValue>{data?.inscricao.history?.nome_talha}</C.BoxLabelValue>
      </C.BoxLabel>
      <C.BoxLabel>
        <C.BoxLabelTitle>Categoria:</C.BoxLabelTitle>
        <C.BoxLabelValue>{data?.inscricao.history?.category}</C.BoxLabelValue>
      </C.BoxLabel>
      <C.BoxLabel>
        <C.BoxLabelTitle>Híbrido inscrito:</C.BoxLabelTitle>
        <C.BoxLabelValue>{data?.inscricao.history?.hibrido}</C.BoxLabelValue>
      </C.BoxLabel>
    </C.ContainerDadosPropriedade>
  );
};
