import styled from 'styled-components';

export const Container = styled.div`
    
`;

export const ContainerDadosPropriedade = styled.div`

`;

export const BoxField = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;
export const BoxField3Col = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;

export const BoxFieldInfoManejoPessoal = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;

export const BoxLabel = styled.div`
    margin-bottom: .5rem;
`;
export const BoxLabelTitle = styled.div`
    font-size: .8rem;
    color: #888;
`;
export const BoxLabelValue = styled.div`

`;

export const BoxLabelTitleSection = styled.div`
    font-size: 1rem;
    color: #888;
`;
export const BoxLabelTitleSectionBlue = styled.div`
    font-size: 1rem;
    color: #444;
    font-weight: bold;
`;

// ESTILIZAÇÃO - INFORMAÇÕES DE MANEJO: IDENTIFICAÇÃO PESSOAL E DE ÁREA

export const BoxCulturaValues = styled.div`
    padding: 0 20px;
`;
export const BoxAlignValues = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;
export const BoxAlignValuesHistoricoCol5 = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 10px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;
export const BoxAlignValuesCol3 = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;
export const BoxAlignValuesInputsFinal = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 20px 0 20px 0;
    gap: .6rem;
    border-bottom: 1px dotted #ebebeb;
    padding-bottom: 10px;
`;

export const BoxHibridosValue = styled.div`
    padding: 0 20px;
`;

// FIM: IDENTIFICAÇÃO PESSOAL E DE ÁREA

// ESTILIZAÇÃO - INFORMAÇÕES DE MANEJO: CORREÇÃO DE SOLO



// FIM: CORREÇÃO DE SOLOS