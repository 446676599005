import React, { useMemo } from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";

interface DataManejo {
  data: IDetailsSubscription;
}

export const InfoManejoProtecaoCultivos: React.FC<DataManejo> = ({ data }) => {
  const mInfos = useMemo(() => {
    const LAGARTA =
      data.inscricao.cultivo?.qtd_lagarta !== undefined &&
      data.inscricao.cultivo?.qtd_lagarta > 0
        ? data.lista.filter((i) => i.nome === "LAGARTA")
        : [];
    const CIGARRA =
      data.inscricao.cultivo?.qtd_cigarra !== undefined &&
      data.inscricao.cultivo?.qtd_cigarra > 0
        ? data.lista.filter((i) => i.nome === "CIGARRA")
        : [];
    const FUNGICIDA =
      data.inscricao.cultivo?.qtd_fungicida !== undefined &&
      data.inscricao.cultivo?.qtd_fungicida > 0
        ? data.lista.filter((i) => i.nome === "FUNGICIDA")
        : [];
    const NEMATICIDA =
      data.inscricao.cultivo?.qtd_biodefenciva !== undefined &&
      data.inscricao.cultivo?.qtd_biodefenciva > 0
        ? data.lista.filter((i) => i.nome === "BIODEFENSIVO")
        : [];
    const HERBICIDA =
      data.inscricao.cultivo?.qtd_herbi !== undefined &&
      data.inscricao.cultivo?.qtd_herbi > 0
        ? data.lista.filter((i) => i.nome === "HERBICIDA")
        : [];

    return {
      LAGARTA,
      CIGARRA,
      FUNGICIDA,
      NEMATICIDA,
      HERBICIDA,
    };
  }, [data]);

  return (
    <C.ContainerDadosPropriedade>
      <>
        <C.BoxLabelTitleSection>Tipo de aplicação</C.BoxLabelTitleSection>
        <br />
        <h4>Herbicida ({data.inscricao.cultivo?.qtd_herbi} aplicações)</h4>
        {mInfos.HERBICIDA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.HERBICIDA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Vegetativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.estadio_veget_feno === "Outros" && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>
          Inseticida (largatas) ({data.inscricao.cultivo?.qtd_lagarta}{" "}
          aplicações)
        </h4>
        {mInfos.LAGARTA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.LAGARTA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Vegetativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.estadio_veget_feno === "Outros" && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>
          Inseticida (cigarrinhas) ({data.inscricao.cultivo?.qtd_cigarra}{" "}
          aplicações)
        </h4>
        {mInfos.CIGARRA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.CIGARRA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Vegetativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.estadio_veget_feno === "Outros" && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>Fungicida ({data.inscricao.cultivo?.qtd_fungicida} aplicações)</h4>
        {mInfos.FUNGICIDA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.FUNGICIDA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Vegetativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.estadio_veget_feno === "Outros" && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}

        <br />
        <h4>
          Biodefensivos ({data.inscricao.cultivo?.qtd_biodefenciva} aplicações)
        </h4>
        {mInfos.NEMATICIDA.length === 0 ? (
          <C.BoxLabelTitle>Não fez</C.BoxLabelTitle>
        ) : (
          mInfos.NEMATICIDA.map((pre) => (
            <>
              <C.BoxField3Col key={`${new Date().getTime() + 1}`}>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Ingrediente ativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.formula}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Dose (kg/ha)</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.dose}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Nome comercial</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.nome_comercial}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Fabricante</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.fabricante}</C.BoxLabelValue>
                </C.BoxLabel>
                <C.BoxLabel>
                  <C.BoxLabelTitle>Estadio Vegetativo</C.BoxLabelTitle>
                  <C.BoxLabelValue>{pre?.estadio_veget_feno}</C.BoxLabelValue>
                </C.BoxLabel>
                {pre?.estadio_veget_feno === "Outros" && (
                  <C.BoxLabel>
                    <C.BoxLabelTitle>Outro</C.BoxLabelTitle>
                    <C.BoxLabelValue>{pre?.nome_outros}</C.BoxLabelValue>
                  </C.BoxLabel>
                )}
              </C.BoxField3Col>
            </>
          ))
        )}
      </>
    </C.ContainerDadosPropriedade>
  );
};
