import { IDetailsSubscription } from "../../types/SystemTypes";
import * as C from "./styles";

interface IData {
  data: IDetailsSubscription;
}

export const Regulamento = ({ data }: IData) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Aceite dos termos: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.accept_term === 1 ? "Sim" : "Não"}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
    </C.ContainerDadosPropriedade>
  );
};
