import { ReactElement } from "react";

export const UF = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const Genre = ["Feminino", "Masculino"];

export const Years = [
  "2021 / 2022",
  "2019 / 2020",
  "2017 / 2018",
  "2015 / 2016",
  "2013 / 2014",
];

export const producer_company_type = ["Produtor", "Empresa"];

export const YesNot = ["Sim", "Não"];

export const Satisfacao = ["Satisfeito", "Não Satisfeito"];

export const Rusticidade = ["Boa", "Mediana", "Ruim"];

export const DistribuicaoSementes = ["Pneumática", "Mecânica", "Outro"];

export const OndeRealizou = ["TSI", "On Farm", "Não realizou"];

export const Sistema = ["Plantio Direto", "Convencional"];

export const CategorieSubscribe = ["1 - Sequeiro", "2 - Irrigado"];

export const SystemProduction = ["Plantio Direto", "Convencional", "Outro"];

export const InfoHibrido = `
    Ao escolher um tipo de híbrido, aparece os campos ao lado para inserção (Área....). Ao salvar, essa informações se reduzem a um menu suspenso com o nome do tipo de híbrido escolhida
    Produtor deve ter opção de incluir mais de um tipo de híbrido
    O lançamento já preenchido pode ser selecionado no menu suspenso para editar, salvar ou excluir informação
`;

export const propertie = ["Fez", "Não fez"];

export const methodPayment = ["pix", "creditCard"];

export type LinksType = {
  title: string;
  links: Array<MenuLinks>;
};

export type MenuLinks = {
  path: string;
  name: string;
  icon?: ReactElement;
};

export const Links: Array<LinksType> = [
  {
    title: "propriedade",
    links: [
      {
        path: "/painel",
        name: "Painel",
      },
      {
        path: "/propriedades",
        name: "Inscrições",
      },
      {
        path: "/usuarios",
        name: "Usuários",
      }
    ],
  },
  {
    title: "cadastros",
    links: [
      {
        path: "/regulamento",
        name: "Regulamento",
      },
      // {
      //   path: "/categorias",
      //   name: "Categorias",
      // },
      {
        path: "/cupons",
        name: "Cupons",
      },
      {
        path: "/produtoresEmpresas",
        name: "Produtores/Empresas",
      },
      {
        path: "/cadastroListas",
        name: "Cadastro de listas",
      },
    ],
  },
  {
    title: "configurações",
    links: [
      {
        path: "/configuracoes",
        name: "Configurações",
      },
    ],
  },
];

export const MockDataGridPropriedades = [
  {
    ID: 1,
    Nome: "Fazenda Bonita",
    Talhao: "Rancho Fundo",
    Endereco: "Riacho da Serra",
    Cidade: "Praia Grande",
    Estado: "RN",
  },
  {
    ID: 2,
    Nome: "Fazenda Bonita",
    Talhao: "Rancho Fundo",
    Endereco: "Riacho da Serra",
    Cidade: "Praia Grande",
    Estado: "RN",
  },
  {
    ID: 3,
    Nome: "Fazenda Bonita",
    Talhao: "Rancho Fundo",
    Endereco: "Riacho da Serra",
    Cidade: "Praia Grande",
    Estado: "RN",
  },
  {
    ID: 4,
    Nome: "Fazenda Bonita",
    Talhao: "Rancho Fundo",
    Endereco: "Riacho da Serra",
    Cidade: "Praia Grande",
    Estado: "RN",
  },
  {
    ID: 5,
    Nome: "Fazenda Bonita",
    Talhao: "Rancho Fundo",
    Endereco: "Riacho da Serra",
    Cidade: "Praia Grande",
    Estado: "RN",
  },
];

export const MockDataGridCupons = [
  {
    ID: 1,
    Cupom: "Cupom 01",
  },
  {
    ID: 2,
    Cupom: "Cupom 02",
  },
  {
    ID: 3,
    Cupom: "Cupom 03",
  },
  {
    ID: 4,
    Cupom: "Cupom 04",
  },
  {
    ID: 5,
    Cupom: "Cupom 05",
  },
];

export const MockDataGridCategoria = [
  {
    ID: 1,
    Categoria: "Categoria 01",
  },
  {
    ID: 2,
    Categoria: "Categoria 02",
  },
  {
    ID: 3,
    Categoria: "Categoria 03",
  },
  {
    ID: 4,
    Categoria: "Categoria 04",
  },
  {
    ID: 5,
    Categoria: "Categoria 05",
  },
];

export const MockDataGrid = [
  ["1", "Leonardo Lopes", "84999998888", "84988887777", "Natal", "RN"],
  ["2", "Lopes Oliveira", "84999998888", "84988887777", "Natal", "RN"],
  ["3", "Aline", "84999998888", "84988887777", "Natal", "RN"],
  ["4", "Júlia", "84999998888", "84988887777", "Natal", "RN"],
  ["5", "Viviane", "84999998888", "84988887777", "Natal", "RN"],
];

export const Status = ["Ativo", "Inativo"];
export const TipoLista = ["Hibridos", "Municipios", "Maquinario", "Defensivos", "Fertilizantes"];