import { IDetailsSubscription } from "../../types/SystemTypes";
import * as C from "./styles";

interface IData {
  data: IDetailsSubscription;
}

export const PagamentoInscricao = ({ data }: IData) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Forma de pagamento: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data?.inscricao.is_cupom
              ? `Cupom - ${data?.inscricao.cupom?.code}`
              : data?.inscricao.payment?.type_payment}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>
    </C.ContainerDadosPropriedade>
  );
};
