import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useCallback, useEffect, useState } from "react";
import {
  Agendamento,
  DadosPripriedade,
  InfoManejoCarrecaoSolo,
  InfoManejoIdentificacaoPessoal,
  InfoManejoImplantacaoCultura,
  InfoManejoNutricaoPlantas,
  InfoManejoProtecaoCultivos,
  PagamentoInscricao,
  Regulamento,
} from "../Visualizacao/index";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { DataProperty, TAppointmens } from "../../types/PropertyTypes";
import { BiChevronLeft } from "react-icons/bi";
import { InfoManejoHistoricoArea } from "../Visualizacao/InfoManejoHistoricoArea";
import { IDetailsSubscription } from "../../types/SystemTypes";

export interface IInfoAgedamento {
  agendamento: TAppointmens;
  propriedade: DataProperty;
}

export const PropriedadeView = () => {
  const navigate = useNavigate();

  const [box1, setBox1] = useState(false);
  const [box2, setBox2] = useState(false);
  const [box3, setBox3] = useState(false);
  const [box4, setBox4] = useState(false);
  const [box5, setBox5] = useState(false);
  const [box6, setBox6] = useState(false);
  const [box7, setBox7] = useState(false);
  const [box8, setBox8] = useState(false);
  const [box9, setBox9] = useState(false);
  const [subscription, setSubscription] = useState<IDetailsSubscription>(
    {} as IDetailsSubscription
  );
  const [boxHistorico, setBoxHistorico] = useState(false);

  const openBox1 = () => setBox1(!box1);
  const openBox2 = () => setBox2(!box2);
  const openBox3 = () => setBox3(!box3);
  const openBox4 = () => setBox4(!box4);
  const openBoxHistorico = () => setBoxHistorico(!boxHistorico);
  const openBox5 = () => setBox5(!box5);
  const openBox6 = () => setBox6(!box6);
  const openBox7 = () => setBox7(!box7);
  const openBox8 = () => setBox8(!box8);
  const openBox9 = () => setBox9(!box9);

  const param = useParams();

  const handleBack = () => {
    navigate("/propriedades");
  };

  const loadDataForms = useCallback(async () => {
    api.get(`/adm/subscription/${param.id}`).then((res) => {
      setSubscription(res.data);
    });
  }, [param.id]);

  useEffect(() => {
    loadDataForms();
  }, [loadDataForms]);

  return (
    <C.Container id="view_print">
      <C.ContentRight>
        <C.BoxButtons>
          <C.Voltar onClick={handleBack}>
            <BiChevronLeft />
            <h4>Voltar</h4>
          </C.Voltar>
          <div>{/* <button onClick={geraPdf}>Imprimir</button> */}</div>
        </C.BoxButtons>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox1}>
            <Title title="Dados pessoais e de propriedade" />
            {box1 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>

          {box1 && (
            <C.BoxContent>
              <DadosPripriedade data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox2}>
            <Title title="Regulamento" />
            {box2 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box2 && (
            <C.BoxContent>
              <Regulamento data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox3}>
            <Title title="Pagamento de inscrição" />
            {box3 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box3 && (
            <C.BoxContent>
              <PagamentoInscricao data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox4}>
            <Title title="Informações de manejo: Identificação pessoal e da área" />

            {box4 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box4 && (
            <C.BoxContent>
              <InfoManejoIdentificacaoPessoal data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBoxHistorico}>
            <Title title="Informações de manejo: Histórico da área" />

            {boxHistorico ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {boxHistorico && (
            <C.BoxContent>
              <InfoManejoHistoricoArea data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox5}>
            <Title title="Informações da área inscrita: Solo" />
            {box5 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box5 && (
            <C.BoxContent>
              <InfoManejoCarrecaoSolo data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox6}>
            <Title title="Informações de manejo: Implantação da cultura" />
            {box6 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box6 && (
            <C.BoxContent>
              <InfoManejoImplantacaoCultura data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox7}>
            <Title title="Informações de manejo: Nutrição de plantas" />
            {box7 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box7 && (
            <C.BoxContent>
              <InfoManejoNutricaoPlantas data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox8}>
            <Title title="Informações de manejo: Proteção de cultivos" />
            {box8 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box8 && (
            <C.BoxContent>
              <InfoManejoProtecaoCultivos data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>

        <C.BoxContentWrapper>
          <C.BoxContentHeader onClick={openBox9}>
            <Title title="Agendamento de auditoria" />
            {box9 ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </C.BoxContentHeader>
          {box9 && (
            <C.BoxContent>
              <Agendamento data={subscription} />
            </C.BoxContent>
          )}
        </C.BoxContentWrapper>
      </C.ContentRight>
    </C.Container>
  );
};
