import { InputHTMLAttributes, useCallback, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import * as C from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    plcholder?: string;
    type?: string;
    width?: string;
}

export const Input = ({ label, plcholder, type, width, ...rest }: Props) => {
    const [visible, setVisible] = useState(false)


    const handleVisible = useCallback(() => {
        setVisible(!visible);
    }, [visible, setVisible])

    return (
        <C.ContainerInput width={width}>
            <C.LabelInput>{label}</C.LabelInput>
            <div className='contentInput'>
                <C.Input type={type === "password" ? (visible ? "text" : type) : type} placeholder={plcholder} {...rest} />
                {type === 'password' &&
                    <button onClick={handleVisible}>
                        {visible ? <AiFillEyeInvisible size={18} /> : <AiFillEye size={18} />}
                    </button>
                }
            </div>
        </C.ContainerInput>
    )
}