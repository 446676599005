import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { Button } from '../../components/Button/Button';
import 'devextreme/dist/css/dx.light.css';

import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';

export const Regulamento = () => {
    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
    const headerValues = [false, 1, 2, 3, 4, 5];

    const [terms, setTerms] = useState();

    const createTerms = useCallback(async () => {
        if(terms==='') {
            return toast.error("Atenção! preencha todos os campos.");
        }
        
        api.post('/adm/regulamento', {
            terms
        }).then(res => {
            if(res.status === 201) {
                toast.success("Termo de uso cadastrado!");
            }
        }).catch((e) => {
            toast.error(`${e.response.data.message}`);
        })
    },[terms]);

    return (
        <C.Container>
            <C.ContentRight>
                <C.HeaderContentRight>
                    <Title title='Regulamento' />
                </C.HeaderContentRight>

                <C.ContentRightForm>
                    <C.BoxFields>
                        <h2>Cadastrar novo regulamento</h2>

                        <HtmlEditor
                        height="725px"
                        onValueChanged={e => setTerms(e.value)}
                        value={terms}
                        >
                        <MediaResizing enabled={true} />
                        <ImageUpload fileUploadMode="base64" />
                        <Toolbar multiline={true}>
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item
                            name="size"
                            acceptedValues={sizeValues}
                            />
                            <Item
                            name="font"
                            acceptedValues={fontValues}
                            />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            <Item
                            name="header"
                            acceptedValues={headerValues}
                            />
                            <Item name="separator" />
                            <Item name="color" />
                            <Item name="background" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="image" />
                            <Item name="separator" />
                            <Item name="clear" />
                            <Item name="codeBlock" />
                            <Item name="blockquote" />
                            <Item name="separator" />
                            <Item name="insertTable" />
                            <Item name="deleteTable" />
                            <Item name="insertRowAbove" />
                            <Item name="insertRowBelow" />
                            <Item name="deleteRow" />
                            <Item name="insertColumnLeft" />
                            <Item name="insertColumnRight" />
                            <Item name="deleteColumn" />
                        </Toolbar>
                        </HtmlEditor>

                    </C.BoxFields>

                    <C.BoxButtonSubmit>
                        <Button title='SALVAR' color='#27AE60' onClick={createTerms} />
                    </C.BoxButtonSubmit>

                </C.ContentRightForm>
            </C.ContentRight>
        </C.Container>
    );
}