import styled from 'styled-components';

export const Container = styled.div`

`;

export const Area = styled.div`
    display: flex;
`;

export const Steps = styled.div`
    flex: 1;
    display: flex;
    height: 100vh;
    margin-bottom: 40px;

    @media screen and (max-width:760px){
        flex-direction: column;
    }
`;


type PropSidebar = {
    open: boolean;
}
export const Sidebar = styled.div<PropSidebar>`
    width: 300px;

    @media screen and ( max-width: 760px ) {
        display: ${prop => prop.open ? 'block' : 'none'};
        width: 100%;
    }

    @media print {
        display: none;
    }
`;

export const Page = styled.div`
    flex: 1;
`;

export const ContentLeft = styled.div`
    width: 100%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    span {
        font-size: 14px;
        color: #525252;
    }

    select {
        width: 100%;
        margin-top: 10px;
        font-size:16px;
        color: inherit;
        padding:10px 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        cursor: pointer;
        outline: 0;
    }
`;

export const CheckSteps = styled.div`
    margin:40px 0;
`;

export const ContentStep = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    padding:10px 0 10px 15px;
    margin-top:10px;
    border-bottom: 1px solid #ccc;

    /* &:nth-child(1) {
        color: #27AE60;
    } */
`;

export const TitleSectionSidebar = styled.span`
    display: block;
    font-size: 1rem;
    color: #888;
    opacity: .8;
    padding-bottom: 5px;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(32, 37, 41, .1);
`;

export const BoxMenu = styled.div`
    padding-right: 3px;
    margin-bottom: 20px;

    a {
        text-decoration: none;
    }
`;

export const LinNav = styled.div`
    color: #254D6B;
    font-size: 1.2rem;
    padding: 10px 0 10px 15px;
    margin: 5px 0;
    border-radius: 6px;
    cursor: pointer;
    opacity:.8;
    transition: all ease .2s;
    
    &:hover {
        color: #fff;
        background-color: rgba(39, 174, 96, .50);
    }

    .active & {
        color: #fff;
        background-color: rgba(39, 174, 96, .89);
    }
    
`;