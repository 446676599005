import { useCallback, useEffect, useState } from 'react';
import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { BiPlus } from 'react-icons/bi';
import { DataGrid, SelectBox, TextBox } from 'devextreme-react';
import { UF, producer_company_type } from '../../data/data';
import { Column, Button } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { TProducerCompany } from '../../types/PropertyTypes';
import api from '../../services/api';
import { toast } from 'react-toastify';
import Autocomplete from "devextreme-react/autocomplete";
import municipiosData from  "../../data/municipios.json";
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { removeSpacesAndSpecialChars } from '../../utils/StringUtils';

export const ProdutoresEmpresas = () => {
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
    const [allProducers, setAllProducers] = useState<TProducerCompany[]>([]);

    const [idRow, setIdRow] = useState();

    const[nome, setNome] = useState('');
    const[email, setEmail] = useState('');
    const[phone, setPhone] = useState('');
    const[cpf_cnpj, setCpfCnpj] = useState('');
    const[endereco, setEndereco] = useState('');
    const[cidade, setCidade] = useState('');
    const[uf, setUf] = useState('');
    const[nome_resp, setNomeResp] = useState('');
    const[phone_resp, setPhoneResp] = useState('');
    const[type, setType] = useState('')

    const togglePopup = () => setPopupVisibility(!isPopupVisible);
    const toggleDelete = () => setModalDelete(!modalDelete);
    const togglePopupEdit = () => setPopupEditVisibility(!isPopupEditVisible);
    const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData)


      const updateDatasource = (e : string) => {
        // municipiosData.filter(i => i["UF"] === e);
        setMunicipiosPopup(municipiosData.filter(i => i["UF"] === e));
      }

    const listProdutoresEmpresas = useCallback(() => {
        api.get('/adm/producer-company?page=1&quantity=1000')
            .then(res => {
                console.log(res.data);
                setAllProducers(res.data)
            })
    }, [])

    const validateFields = () => {
        console.log(`NOME = ${nome}, TYPE = ${type} NOME_RESP = ${nome_resp} PHONE_RESP = ${phone_resp}`);
        // let validateCompanyFields = false;
        // if (type === 'Empresa') {
        //     validateCompanyFields = nome_resp === '' || phone_resp === '';
        // }
        let validateCompanyFields = type === 'Empresa' ? (nome_resp === '' || phone_resp === '') : true;
        return !(nome === '' || 
            email === '' || 
            phone === '' || 
            cpf_cnpj === '' || 
            endereco === '' || 
            cidade === '' || 
            uf === '' ||
            type === '') || !validateCompanyFields;             
    }

    const handleValueChangeCidade = (e: any) => {
        console.log(`e.value = ${e.value}`);
        const item = municipiosPopup.find(h => h.MUN.toLowerCase().startsWith(e.value.toLowerCase()));
        console.log(`item = ${item}`);
        // const item = hibridosData.find(i => i.Cultivar === e.value);
        setCidade(e.value);
        if (!item) {
          toast.warning(`Por favor informe um valor válido para a cidade.`);
        }
      };

      const handleOnFocusOutCidade = (e: any) => {
        if (cidade === undefined || cidade === "") {
          return;
        }
        const item = municipiosPopup.find(i => i.MUN === cidade);
        if (!item) {
            setCidade(''); // Limpa o campo ou mantenha o último valor válido
          toast.warning(`Por favor informe um valor válido para a cidade.`);
        }
      };

    const createProducerCompany = () => {
        if (!validateFields()) {
            return toast.error("Atenção! preencha todos os campos.");
        }

        const pure = removeSpacesAndSpecialChars(cpf_cnpj!);

        if (type === 'Produtor') {
            if (!cpf.isValid(pure || "")) {
                return toast.error('Preencha um CPF válido!');
            }
        } else {
            if (!cnpj.isValid(pure || "")) {
                return toast.error('Preencha um CNPJ válido!');
            }
        }

        api.put('/adm/producer-company', {
            nome,
            email,
            phone,
            cpf_cnpj : pure,
            endereco,
            cidade,
            uf,
            nome_resp,
            phone_resp,
            type
        }).then(res => {
            if (res.status === 201) {
                setPopupVisibility(false)
                toast.success("Registro criado!");
            }
        }).catch((e) => {
            toast.error(`${e.response.data.message}`);
        }).finally(() => {
            listProdutoresEmpresas();
        })
    };

    const removerRegistro = () => {
        api.delete(`/adm/producer-company/${idRow}`)
            .then(res => {
                toast.success("Registro removido com sucesso!");
                listProdutoresEmpresas()
            }).catch((error) => {
                console.log(error.response.data.message);
                toast.error("Error ao remover o registro.");
            }).finally(() => {
                setModalDelete(false)
            })
    }

    const alteraRegistro = () => {
        if (!validateFields()) {
            return toast.error("Atenção! preencha todos os campos.");
        }

        const pure = removeSpacesAndSpecialChars(cpf_cnpj!);
        console.log(`pure = ${pure}`)
        if (type === 'Produtor') {
            if (!cpf.isValid(pure || "")) {
                return toast.error('Preencha um CPF válido!');
            }
        } else {
            if (!cnpj.isValid(pure || "")) {
                return toast.error('Preencha um CNPJ válido!');
            }
        }

        api.post(`/adm/producer-company/${idRow}`, {
            nome,
            email,
            phone,
            cpf_cnpj : pure,
            endereco,
            cidade,
            uf,
            nome_resp,
            phone_resp,
            type
        }).then(res => {
            if (res.status === 201) {
                setPopupVisibility(false)
                toast.success("Registro alterado com sucesso!");
                // clearProducerCompanyData();
            }
        }).catch((e) => {
            toast.error(`${e.response.data.message}`);
        }).finally(() => {
            listProdutoresEmpresas();
            setPopupEditVisibility(false);
        })
    }
  
    const getIdToDelete = (e: any) => {
        setIdRow(e.row.key);
        setModalDelete(true)
    }

    const getProducerCompanyData = (e: any) => {
        console.log(e);
        console.log(e.row.data.quantity);
        setIdRow(e.row.key);
        setNome(e.row.data.nome);
        setEmail(e.row.data.email);
        setPhone(e.row.data.phone);
        setCpfCnpj(e.row.data.cpf_cnpj);
        setEndereco(e.row.data.endereco);
        setCidade(e.row.data.cidade);
        setUf(e.row.data.uf);
        setNomeResp(e.row.data.nome_resp);
        setPhoneResp(e.row.data.phone_resp);
        setType(e.row.data.type);

        setPopupEditVisibility(true)
    }

    useEffect(() => {
        listProdutoresEmpresas();
    }, [listProdutoresEmpresas])

    return (
        <C.Container>
            <C.ContentRight>
                <C.HeaderContentRight>
                    <Title title='Produtores / Empresas' />
                </C.HeaderContentRight>

                <C.ContentRightForm>
                    <C.BoxFields>
                        <C.BoxRegulamento>
                            <C.BoxButtonSubmit>
                                <button onClick={togglePopup}>
                                    <BiPlus size={16} />
                                    Cadastrar
                                </button>
                            </C.BoxButtonSubmit>
                            <br />
                            <br />
                            <DataGrid
                                id="cupom"
                                dataSource={allProducers}
                                keyExpr="id"
                                showBorders={false}
                                showColumnLines={false}
                                columnResizingMode="nextColumn"
                                allowColumnResizing={true}
                                rowAlternationEnabled={true}
                            >
                                <Column caption="Id" dataField='id' width='60' />
                                <Column caption="Nome" dataField='nome' width='80' />
                                <Column caption="Email" dataField='email' />
                                <Column caption="Phone" dataField='phone' />
                                <Column caption="Cpf/Cnpj" dataField='cpf_cnpj' />
                                <Column caption="Endereço" dataField='endereco' width='60' />
                                <Column caption="Cidade" dataField='cidade' width='80' />
                                <Column caption="UF" dataField='uf' width='80' />
                                <Column caption="Tipo" dataField='type' width='80' />

                                <Column caption="Nome Responsável" dataField='nome_resp' width='80' />
                                <Column caption="Phone Responsável" dataField='phone_resp' width='80' />
                                <Column caption="Data de criação" dataField='created_at' width='80' />
                                <Column caption="Data de edição" dataField='updated_at' width='80' />
                                <Column caption='AÇÕES' width={100} alignment='center' type='buttons' >                        
                                    <Button name='editar' icon='edit' onClick={(e: any) => getProducerCompanyData(e)} />
                                    <Button name='excluir' icon='remove' onClick={(e: any) => getIdToDelete(e)} />
                                </Column>
                            </DataGrid>
                        </C.BoxRegulamento>
                    </C.BoxFields>
                </C.ContentRightForm>

                <Popup
                    title='Cadastro de produtores / empresas'
                    visible={isPopupVisible}
                    hideOnOutsideClick={true}
                    onHiding={togglePopup}
                    width={500}
                    height={760}
                >
                    <C.ContentModal>
                        <C.ContentModalInput>
                        <SelectBox
                                items={producer_company_type}
                                // value={type}
                                onValueChange={(e) => {
                                   setType(e);
                                   console.log(`type = ${type}`)
                                }}
                                stylingMode="underlined"
                                labelMode="static"
                                label="Tipo (*)"
                                width="100%"
                                height={50}
                                style={{ marginRight: "20px", color: "#444" }}
                            />
                            <TextBox
                                stylingMode='underlined'
                                value={nome}
                                labelMode='static'
                                label={`Nome ${type} (*)`}
                                placeholder='Digite um nome...'
                                width={320}
                                height={50}
                                onValueChanged={e => {setNome(e.value); console.log(`nome = ${e.value}`)}}
                            />
                            <TextBox
                                stylingMode='underlined'
                                // value={email}
                                labelMode='static'
                                label={`Email ${type} (*)`}
                                placeholder='Email'
                                width={320}
                                height={50}
                                onValueChanged={e => setEmail(e.value)}
                            />
                            <TextBox
                                stylingMode='underlined'
                                // value={phone}
                                labelMode='static'
                                label={`Fone ${type} (*)`}
                                placeholder='Um telefone...'
                                width={320}
                                height={50}
                                mask="(00) 0 0000-0000"
                                onValueChanged={e => setPhone(e.value)}
                            />
                             <TextBox
                                stylingMode='underlined'
                                // value={cpf_cnpj}
                                labelMode='static'
                                label={`${type === 'Empresa' ? 'CNPJ Empresa' : 'CPF Produtor'} (*)`}
                                placeholder='Um CPF ou CNPJ...'
                                width={320}
                                height={50}
                                mask={type === 'Empresa' ? "00.000.000/0000-00" : "000.000.000-00"}
                                onValueChanged={e => setCpfCnpj(e.value)}
                            />
                             <TextBox
                                stylingMode='underlined'
                                // value={endereco}
                                labelMode='static'
                                label={`Endereço ${type} (*)`}
                                placeholder='Um endereço...'
                                width={320}
                                height={50}
                                onValueChanged={e => setEndereco(e.value)}
                            />
                            <SelectBox
                                items={UF}
                                value={uf}
                                onValueChange={(e) => {
                                    setUf(e);
                                    updateDatasource(e);
                                  }}
                                stylingMode="underlined"
                                labelMode="static"
                                label={`UF ${type} (*)`}
                                width="100%"
                                height={50}
                                style={{ marginRight: "20px", color: "#444" }}
                            />
                             {/* <TextBox
                                stylingMode='underlined'
                                // value={cidade}
                                labelMode='static'
                                label='Cidade'
                                placeholder='Um cidade...'
                                width={320}
                                height={50}
                                onValueChanged={e => setCidade(e.value)}
                            /> */}
                            <Autocomplete
                            width={320}
                            height={50}
                                dataSource={municipiosPopup}
                                valueExpr="MUN"
                                searchExpr="MUN"
                                value={cidade}
                                label={`Cidade ${type} (*)`}
                                //onValueChanged={(e) => setCidade(e.value)}
                                onValueChanged={handleValueChangeCidade}
                                onFocusOut={handleOnFocusOutCidade}
                                labelMode="static"
                                stylingMode="underlined"  
                        />
                            
                            {type === 'Empresa' && (<C.ContentRightFormBoxLeft>
                             <TextBox
                                stylingMode='underlined'
                                // value={nome_resp}
                                labelMode='static'
                                label='Nome do Responsável Empresa(*)'
                                placeholder='Um nome de responsável...'
                                width={320}
                                height={50}
                                onValueChanged={e => setNomeResp(e.value)}
                            />
                           <TextBox
                                stylingMode='underlined'
                                // value={phone_resp}
                                labelMode='static'
                                label='Telefone do Responsável Empresa(*)'
                                mask="(00) 00000-0000"
                                placeholder='Um telefone...'
                                width={320}
                                height={50}
                                onValueChanged={e => setPhoneResp(e.value)}
                            />                   
                            </C.ContentRightFormBoxLeft>
                        )}
                        </C.ContentModalInput>
                        <C.BoxButtonSubmitModal>
                            <button onClick={createProducerCompany}>
                                <BiPlus size={18} />
                                Salvar
                            </button>
                        </C.BoxButtonSubmitModal>
                    </C.ContentModal>
                </Popup>        

                <Popup
                    title='Remover Produtor / Empresa'
                    visible={modalDelete}
                    hideOnOutsideClick={true}
                    onHiding={toggleDelete}
                    width={400}
                    height={200}
                >
                    <C.ContentModal>
                        <C.ContentModalInput>
                            <p>Tem certdeza que deseja remover esse registro?</p>
                        </C.ContentModalInput>
                        <C.BoxButtonSubmitModal>
                            <button onClick={() => setModalDelete(false)} style={{
                                background: "#e75b65"
                            }}>
                                Cancelar
                            </button>
                            <button onClick={removerRegistro}>
                                Confirmar
                            </button>
                        </C.BoxButtonSubmitModal>
                    </C.ContentModal>
                </Popup>

                <Popup
                    title='Edição de produtores / empresas'
                    visible={isPopupEditVisible}
                    hideOnOutsideClick={true}
                    onHiding={togglePopupEdit}
                    width={400}
                    height={760}
                >
                    <C.ContentModal>
                        <C.ContentModalInput>
                        <SelectBox
                                items={producer_company_type}
                                value={type}
                                onValueChange={(e) => {
                                   setType(e);
                                }}
                                stylingMode="underlined"
                                labelMode="static"
                                label="Tipo (*)"
                                width="100%"
                                height={50}
                                style={{ marginRight: "20px", color: "#444" }}
                            />
                            <TextBox
                                stylingMode='underlined'
                                labelMode='static'
                                value={nome}
                                label={`Nome ${type} (*)`}
                                placeholder='Digite um nome...'
                                width={320}
                                height={50}
                                onValueChanged={e => setNome(e.value)}
                            />
                            <TextBox
                                value={email}
                                stylingMode='underlined'
                                labelMode='static'
                                label={`Email ${type} (*)`}
                                placeholder='Email'
                                width={320}
                                height={50}
                                onValueChanged={e => setEmail(e.value)}
                            />
                            <TextBox
                                stylingMode='underlined'
                                value={phone}
                                labelMode='static'
                                label={`Fone ${type} (*)`}
                                placeholder='Um telefone...'
                                width={320}
                                height={50}
                                mask="(00) 0 0000-0000"
                                onValueChanged={e => setPhone(e.value)}
                            />
                             <TextBox
                                stylingMode='underlined'
                                value={cpf_cnpj}
                                labelMode='static'
                                label={`CPF / CNPJ ${type} (*)`}
                                placeholder='Uma CPF ou CNPJ...'
                                mask={type === 'Empresa' ? "00.000.000/0000-00" : "000.000.000-00"}
                                width={320}
                                height={50}
                                onValueChanged={e => setCpfCnpj(e.value)}
                            />
                             <TextBox
                                stylingMode='underlined'
                                value={endereco}
                                labelMode='static'
                                label={`Endereço ${type} (*)`}
                                placeholder='Uma endereço...'
                                width={320}
                                height={50}
                                onValueChanged={e => setEndereco(e.value)}
                            />
                            <SelectBox
                                items={UF}
                                value={uf}
                                onValueChange={(e) => {
                                    setUf(e);
                                    updateDatasource(e);
                                  }}
                                stylingMode="underlined"
                                labelMode="static"
                                label={`UF ${type} (*)`}
                                width="100%"
                                height={50}
                                style={{ marginRight: "20px", color: "#444" }}
                            />
                             {/* <TextBox
                                stylingMode='underlined'
                                value={cidade}
                                labelMode='static'
                                label='Cidade'
                                placeholder='Uma cidade...'
                                width={320}
                                height={50}
                                onValueChanged={e => setCidade(e.value)}
                            /> */}

                            <Autocomplete
                            width={320}
                            height={50}
                                dataSource={municipiosPopup}
                                valueExpr="MUN"
                                searchExpr="MUN"
                                value={cidade}
                                label={`Cidade ${type} (*)`}
                                onValueChanged={(e) => setCidade(e.value)}
                                labelMode="static"
                                stylingMode="underlined"
  
                        />
                            
                            {type === 'Empresa' && (<C.ContentRightFormBoxLeft>
                             <TextBox
                                stylingMode='underlined'
                                value={nome_resp}
                                labelMode='static'
                                label='Nome do Responsável Empresa'
                                placeholder='Um nome de responsável...'
                                width={320}
                                height={50}
                                onValueChanged={e => setNomeResp(e.value)}
                            />
                           <TextBox
                                stylingMode='underlined'
                                value={phone_resp}
                                labelMode='static'
                                label='Telefone do Responsável Empresa(*)'
                                mask="(00) 00000-0000"
                                placeholder='Um telefone...'
                                width={320}
                                height={50}
                                onValueChanged={e => setPhoneResp(e.value)}
                            />                   
                            </C.ContentRightFormBoxLeft>
                        )}
                        </C.ContentModalInput>
                  <C.BoxButtonSubmitModal>
                            <button onClick={() => setPopupEditVisibility(false)} style={{
                                background: "#e75b65"
                            }}>
                                Cancelar
                            </button>
                            <button onClick={alteraRegistro}>
                                Confirmar
                            </button>
                        </C.BoxButtonSubmitModal>
                    </C.ContentModal>
                </Popup>


            </C.ContentRight>
        </C.Container>
    );
}