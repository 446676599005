import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    margin-bottom: 40px;

    @media screen and (max-width:760px) {
        padding: 2%;
    }
`;

export const ContentLeft = styled.div`
    width: 25%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    input[type="date"] {
        width: 100%;
        margin-top: 10px;
        color: inherit;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        outline: 0;
    }
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px 0;
    }
`;

export const HeaderContentRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: #777;
        font-size:12px;
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        margin-bottom:10px;
    }
`;

export const ContentRightForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
`;

export const BoxFields = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 420px;
    
    h2 {
        color: #444;
        margin: 10px 0;
    }
`;

export const ContentRightFormBoxLeft = styled.div`
    flex: 1;
    padding: 0 20px 0 0;

    @media screen and (max-width: 760px){
        padding: 0;
    }
`;
export const ContentRightFormBoxRight = styled.div`
    flex: 1;
    padding: 0 0 0 20px;
`;

export const BoxButtonSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;

    button {
        width: 143px;
        @media screen and (max-width:760px){
            width: 100%;
            margin-bottom:0;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        margin-bottom: 40px;
    }
`;

export const GroupInputs = styled.div`
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:10px 0;

    @media screen and (max-width: 760px) {
        flex-direction: column;
    }
`;

export const BoxInput = styled.div`
    width: 100%;
    border-right: 10px solid #fff;
`;