import * as C from "./styles";
import "devextreme/dist/css/dx.light.css";
import { CardInfo } from "../../components/CardInfo";
import { DataGrid } from "devextreme-react";
import { BiHome, BiMoney } from "react-icons/bi";
import { Title } from "../../components/Title/Title";
import { Column } from "devextreme-react/data-grid";
import { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import {
  TCupons,
  TDataCards,
  TListAppointments,
} from "../../types/PropertyTypes";

interface IPayments {
  id: number;
  property_id: number;
  transaction_id: string;
  transaction_amount: number;
  type_payment: string;
  status: string;
  status_detail: string;
}

export const Painel = () => {
  const [infoCards, setInfoCards] = useState<TDataCards>({} as TDataCards);
  const [lastRegistered, setLastRegistered] = useState([]);
  const [lastAppointments, setListAppointments] = useState<TListAppointments[]>(
    []
  );
  const [lastPayments, setLastPayments] = useState<IPayments[]>([]);
  const [cupons, setCupons] = useState<TCupons[]>([]);

  // const allowDeleting = useCallback((e: any) => {
  //     return e.row.rowIndex % 2 === 1;
  // },[])

  const loadCards = useCallback(async () => {
    api.get("/adm/cards").then((res) => {
      setInfoCards(res.data);
    });
  }, []);

  const loadPayments = useCallback(async () => {
    api.get("/adm/payments").then((res) => {
      setLastPayments(res.data);
    });
  }, []);

  const loadLastRegistered = useCallback(async () => {
    api.get("/adm/subscription?page=1&quantity=10").then((res) => {
      setLastRegistered(res.data);
    });
  }, []);

  const loadCupons = useCallback(async () => {
    api.get("/adm/cupons?page=1&quantity=10").then((res) => {
      console.log(res.data);

      setCupons(res.data);
    });
  }, []);

  const loadLastAppointments = useCallback(async () => {
    api.get("/adm/appoitments?page=1&quantity=10").then((res) => {
      console.log(res.data);

      setListAppointments(res.data);
    });
  }, []);

  useEffect(() => {
    loadCards();
    loadLastRegistered();
    loadCupons();
    loadLastAppointments();
    loadPayments();
  }, [
    loadCards,
    loadLastRegistered,
    loadCupons,
    loadLastAppointments,
    loadPayments,
  ]);

  console.log(lastAppointments);

  return (
    <C.Container>
      <C.HeaderContentRight>
        <Title title="Painel" />
      </C.HeaderContentRight>

      <C.SectionCards>
        <CardInfo
          title="Propriedades cadastradas"
          value={infoCards.properties}
          icon={<BiHome />}
        />
        <CardInfo
          title="Pagamentos realizados"
          value={infoCards?.payments}
          icon={<BiMoney />}
        />
        {/* <CardInfo
          title="Vistorias agendadas"
          value={infoCards?.appointments}
          icon={<BiCalendar />}
        />
        <CardInfo
          title="Vistorias realizadas"
          value={infoCards?.appointmentsCheck}
          icon={<BiCalendarCheck />}
        /> */}
      </C.SectionCards>

      <C.SectionDoubleTable>
        <C.TableWrapper>
          <h2>Últimos cadastrados</h2>
          <C.TableContent>
            <DataGrid
              id="cadastrados"
              keyExpr="id"
              dataSource={lastRegistered}
              showBorders={false}
              showColumnLines={false}
              columnResizingMode="nextColumn"
              allowColumnResizing={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
            >
              <Column caption="Id" dataField="id" width="60" />
              <Column caption="Nome" dataField="resp.nome_resp" />
              <Column caption="Talhao" dataField="history.nome_talha" />
              <Column caption="Endereço" dataField="property.endereco" />
              <Column caption="Cidade" dataField="property.cidade" />
              <Column caption="Estado" dataField="property.estado" />
            </DataGrid>
          </C.TableContent>
        </C.TableWrapper>
    
      </C.SectionDoubleTable>

      <C.SectionDoubleTable>
        <C.TableBottom>
          <h2>Últimos pagamentos</h2>
          <DataGrid
            id="ultimos-pagamentos"
            keyExpr="id"
            dataSource={lastPayments}
            showBorders={false}
            showColumnLines={false}
            columnResizingMode="nextColumn"
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
          >
            <Column dataField="id" width="60" />
            <Column dataField="status" caption="Status do pagamento" />
            <Column dataField="property_id" caption="Id da propriedade" />
          </DataGrid>
        </C.TableBottom>
        <C.TableWrapper>
          <h2>Últimos cupons resgatados</h2>
          <DataGrid
            id="cupons"
            keyExpr="id"
            dataSource={cupons}
            showBorders={false}
            showColumnLines={false}
            columnResizingMode="nextColumn"
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
          >
            <Column caption="Código Cupom" dataField="code" />
            <Column caption="Nome" dataField="description" />
            <Column
              caption="Data Resgate"
              dataField="updated_at"
              dataType="date"
            />
          </DataGrid>
        </C.TableWrapper>
      </C.SectionDoubleTable>
    </C.Container>
  );
};
