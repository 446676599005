import styled from 'styled-components';

export const Container = styled.div`
    
    @media print {
        padding: 0;
        margin: 0;
    }
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px 0;
    }
`;

export const Voltar = styled.div`
    display: flex;
    align-items: center;
    width: 80px;
    cursor: pointer;
    
    h4 {
        font-weight: normal;
    }

    @media print {
        display: none;
    }
`;

export const BoxContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius:4px;
    margin: 15px 0;
    box-shadow: 2px 2px 8px rgba(0,0,0,.1);
    transition: all .4s ease;

    &:hover {
        box-shadow: 2px 2px 8px rgba(0,0,0,.2);
    }

    @media print {
        box-shadow: none;
    }
`;

export const BoxContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    @media print {
        
        svg {
            display: none;
        }
    }
`;

export const BoxContent = styled.div`
    width: 100%;
    margin-top: 1.2rem;
    padding: 10px 0;
    border-top: 1px dotted #ebebeb;

    label {
        font-size: .8rem;
    }
`;

export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px;
    padding: 0 20px 0 0;

    div {
        button {
            padding: 8px 15px;
            background-color: #fff;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            cursor: pointer;
            transition: all .3s ease;
            color: #444;
            box-shadow: 1px 2px 15px rgba(0,0,0,.1);

            &:hover {
                background-color: rgba(118,118,118,.085);
            }
        }
    }

    @media print {
        display: none;
    }
`;