import { ReactNode } from 'react';
import * as C from './styles';

type PropsCard = {
    title: string;
    value: number;
    icon?: ReactNode;

}

export const CardInfo = ({ title, value, icon }: PropsCard) => {
    return(
        <C.CardsMetrica>
            <h3>{title}</h3>
            <C.CardMetricaValue>
                <C.CardMetricaIcon>{icon}</C.CardMetricaIcon>
                <span>{value}</span>
            </C.CardMetricaValue>
        </C.CardsMetrica>
    );
}