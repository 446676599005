import React from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";

interface DataManejo {
  data: IDetailsSubscription;
}

export const InfoManejoImplantacaoCultura: React.FC<DataManejo> = ({
  data,
}) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxField>
        <C.BoxLabel>
          <C.BoxLabelTitle>Híbrido usado na área inscrita: </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.inscricao.history?.hibrido}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Fornecedor: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.fornecedor}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Sistema de produção: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.sistema_producao}
          </C.BoxLabelValue>
        </C.BoxLabel>

        <C.BoxLabel>
          <C.BoxLabelTitle>População de plantas: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.populacao_almejada}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Espaçamento entre linhas: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.espaco_linha}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Número de sementes: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.numero_semente}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Realiza refúgio: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.realiza_refugio}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Híbrido de refúgio: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.hibrido_refugio}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Tratamento de sementes: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.tratamento_semente}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Nome do pacote de defensivos: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.nome_pacte_trat_sement}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Nome do pacote de inoculantes: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.nome_pacte_trat_inocul}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Nome do pacote de bioestimulantes: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.nome_pacte_trat_bioestim}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Modelo e marca da semeadora: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.modelo_semeadora}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Ano semeadora: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.ano_semeadora}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Modelo e marca da pulverizador: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.modelo_pulverizador}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Ano pulverizador: </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.culture_info?.ano_pulverizador}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField>

      {/* <br />
      <h4>Biotecnologia </h4>
      <br />

      <C.BoxField3Col>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Evento transgênero semeado (PRO, PWU, VIP, etc):{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.inscricao.trangenico}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Possui tolerância a herbicida? </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {yesOrNot(data.inscricao.tolerancia_herbicida)}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Eficiencia da tecnologia de tolerância a herbicida{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.eficiencia_tolerancia}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Possui resistência a insetos? </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {yesOrNot(data.inscricao.resist_insetos)}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Eficiência da tecnologia de resistência a insetos:{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.inscricao.eficiencia_insetos}</C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField3Col> */}

      {/* <br />
      <h4>Tratamento de sementes </h4>
      <br />

      <C.BoxField3Col>
        <C.BoxLabel>
          <C.BoxLabelTitle>Onde realizou? </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {showOption(data.inscricao.trate_semente)}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>Pacote de desensivos TSI - Produto </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.inscricao.pct_defencivos}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Pacote de defensivos do TSI - Dose [kg/ha ou ml/hs]{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.pct_defencivos_dose}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Pacote de inoculante no TSI - Produto
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.inscricao.pct_inoculante}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Pacote de inoculante no TSI - Dose [kg/ha ou ml/ha]{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.pct_inoculante_dose}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Pacote de bioestimulante no TSI - Produto
          </C.BoxLabelTitle>
          <C.BoxLabelValue>{data.inscricao.pct_estimulante}</C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Pacote de bioestimulante no TSI - Dose [kg/ha ou ml/ha]{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.pct_estimulante_dose}
          </C.BoxLabelValue>
        </C.BoxLabel>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Pretende insvestir em TSI na próxima safra?{" "}
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {yesOrNot(data.inscricao.tsi_proxima)}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxField3Col> */}
    </C.ContainerDadosPropriedade>
  );
};
