import React from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";
interface DataInfoPessoalEArea {
  data: IDetailsSubscription;
}

export const InfoManejoHistoricoArea: React.FC<DataInfoPessoalEArea> = ({
  data,
}) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxCulturaValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Nome do talhão inscrito: </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.nome_talha}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Categoria inscrita: </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.category}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido inscrito: </C.BoxLabelTitle>
            <C.BoxLabelValue>{data.inscricao.history?.hibrido}</C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Densidade populacional: </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.densidade_media}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Produtividade: </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.expec_produtividade}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
      </C.BoxCulturaValues>
      <C.BoxLabelTitleSectionBlue>
        Histórico da área{" "}
      </C.BoxLabelTitleSectionBlue>

      <C.BoxCulturaValues>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabelTitle>
            <label>Ano de Semeadura</label>
          </C.BoxLabelTitle>
          <C.BoxLabelTitle>
            <label>Safra</label>
          </C.BoxLabelTitle>
          <C.BoxLabelTitle>
            <label>Cultura</label>
          </C.BoxLabelTitle>
        </C.BoxAlignValuesHistoricoCol5>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.ano_1}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.safra_1}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>
              {data.inscricao.history?.cultura_1}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesHistoricoCol5>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.ano_2}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.safra_2}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>
              {data.inscricao.history?.cultura_2}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesHistoricoCol5>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.ano_3}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.safra_3}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>
              {data.inscricao.history?.cultura_3}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesHistoricoCol5>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.ano_4}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.safra_4}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>
              {data.inscricao.history?.cultura_4}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesHistoricoCol5>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.ano_5}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.safra_5}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>
              {data.inscricao.history?.cultura_5}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesHistoricoCol5>
        <C.BoxAlignValuesHistoricoCol5>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.ano_6}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>{data.inscricao.history?.safra_6}</C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelValue>
              {data.inscricao.history?.cultura_6}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesHistoricoCol5>
      </C.BoxCulturaValues>

      <C.BoxLabelTitleSectionBlue>
        Tecnologia de produção e gestão{" "}
      </C.BoxLabelTitleSectionBlue>

      <C.BoxHibridosValue>
        <C.BoxAlignValuesCol3>
          <C.BoxLabel>
            <C.BoxLabelTitle>
              Utiliza ferramente de agricultura digital?:
            </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.usa_fadigital}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>
              Se sim, qual ferramenta utilizada?
            </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.ferramenta_fadigital
                ? data.inscricao.history?.ferramenta_fadigital
                : " - "}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Nível de utilização</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.nivel_fadigital
                ? data.inscricao.history?.nivel_fadigital
                : " - "}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>Satisfação quanto ao uso</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.nivel_fadigital
                ? data.inscricao.history?.nivel_fadigital
                : " - "}
            </C.BoxLabelValue>
          </C.BoxLabel>
          <C.BoxLabel>
            <C.BoxLabelTitle>
              Pretende investir na próxima safra?
            </C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.history?.repetir_fadigital
                ? data.inscricao.history?.repetir_fadigital
                : " - "}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValuesCol3>
      </C.BoxHibridosValue>
    </C.ContainerDadosPropriedade>
  );
};
