import React from "react";
import * as C from "./styles";
import { IDetailsSubscription } from "../../types/SystemTypes";
interface DataInfoPessoalEArea {
  data: IDetailsSubscription;
}

export const InfoManejoIdentificacaoPessoal: React.FC<DataInfoPessoalEArea> = ({
  data,
}) => {
  return (
    <C.ContainerDadosPropriedade>
      <C.BoxLabelTitleSection>
        Quais foram as culturas exploradas na safra passada?{" "}
      </C.BoxLabelTitleSection>

      <C.BoxCulturaValues>
        {data.inscricao.property_info?.soja === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Soja</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.milho === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Milho</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.algodao === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Algodão</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.trigo === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Trigo</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.sorgo === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Sorgo</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.feijao === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Feijão</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.cafe === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>Café</C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
        {data.inscricao.property_info?.outros === 1 && (
          <C.BoxAlignValues>
            <C.BoxLabel>
              <C.BoxLabelValue>
                Outros: {data.inscricao.property_info?.desc_outros}
              </C.BoxLabelValue>
            </C.BoxLabel>
          </C.BoxAlignValues>
        )}
      </C.BoxCulturaValues>

      <C.BoxLabelTitleSection>
        Quais híbridos de milho está sendo cutivado na safra atual?{" "}
      </C.BoxLabelTitleSection>

      <C.BoxHibridosValue>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Quantidade de híbridos:</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.property_info?.qtd_hibridos}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido 1:</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.property_info?.hibrido_1}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido 2:</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.property_info?.hibrido_2}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido 3:</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.property_info?.hibrido_3}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido 4:</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.property_info?.hibrido_4}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
        <C.BoxAlignValues>
          <C.BoxLabel>
            <C.BoxLabelTitle>Híbrido 5:</C.BoxLabelTitle>
            <C.BoxLabelValue>
              {data.inscricao.property_info?.hibrido_5}
            </C.BoxLabelValue>
          </C.BoxLabel>
        </C.BoxAlignValues>
      </C.BoxHibridosValue>
      <C.BoxAlignValuesInputsFinal>
        <C.BoxLabel>
          <C.BoxLabelTitle>
            Área total de milho semeada na safra atual?
          </C.BoxLabelTitle>
          <C.BoxLabelValue>
            {data.inscricao.property_info?.area_total_milho}
          </C.BoxLabelValue>
        </C.BoxLabel>
      </C.BoxAlignValuesInputsFinal>
    </C.ContainerDadosPropriedade>
  );
};
